import * as React from "react";
import { Col, Container, Row } from "react-bootstrap";
import styled from "styled-components";
import { Typography } from "../ui/Typography";
import HubspotForm from "react-hubspot-form";
import { LinkPosition } from "./ImageCopy";

const FormContainer = styled(Container)`
  padding: 1rem 20vw;
  .col {
    padding: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  @media only screen and (max-width: 767px) {
    padding: 1rem 10vw;
  }
  @media only screen and (max-width: 455px) {
    padding: 1rem;
  }
`;

const ContactForm = ({ id }: { id: string }) => {
  return (
    <>
      <FormContainer fluid>
      <LinkPosition id={id} />
        <Row>
          <Col>
            <Typography.BLOCKQUOTE>FIND OUT MORE</Typography.BLOCKQUOTE>
            <Typography.H2>CONTACT US</Typography.H2>
          </Col>
        </Row>
        <Row>
          <Col>
            <HubspotForm
              portalId={"48824267"}
              formId={"d35441f2-8059-431d-9e04-8e6f84e77e54"}
            />
          </Col>
        </Row>
      </FormContainer>
    </>
  );
};

export default ContactForm;
